<template>
    <div class="cross-border-modal">
        <h1 class="mb-4">{{ translate('title') }}</h1>
        <div class="location mb-4">
            <div class="text">{{ message }}</div>
            <div class="region mt-1">
                <UIFlag v-if="productLocation" class="large mr-2" :code="detectedRegion"></UIFlag>
                <span>{{ productCurrency ? currencyOfCountry : currentLocation }}</span>
            </div>
        </div>
        <div class="product-region mb-4">
            <div class="text">{{ availabilityMessage }}</div>
            <div class="region mt-1">
                <UIFlag v-if="productLocation" class="large mr-2" :code="modal.data.region"></UIFlag>
                <span>{{ productCurrency || productLocation }}</span>
            </div>
        </div>
        <UIButton class="w-100" @click="hideModal">{{ translate('button.ok') }}</UIButton>
    </div>
</template>

<script>
import { UIButton, UIFlag } from '@dundle/ui/components';
import { mapGetters, mapActions } from 'vuex';
import { getCurrency } from '@dundle/utils/locale-currency';
import TranslationMixin from '~/mixins/TranslationMixin';

export default {
    components: {
        UIButton,
        UIFlag,
    },
    mixins: [TranslationMixin],

    computed: {
        ...mapGetters({
            detectedRegion: 'locale/detectedRegion',
            modal: 'ui/activeModal',
        }),

        currentLocation() {
            return this.translate(`country.${this.detectedRegion?.toUpperCase()}`);
        },

        currencyOfCountry() {
            return getCurrency(this.$locale.iso);
        },

        message() {
            return this.productCurrency ? this.translate('currencyMessage') : this.translate('locationMessage');
        },

        availabilityMessage() {
            return this.productCurrency
                ? this.translate('currencyAvailabilityMessage')
                : this.translate('availabilityMessage');
        },

        productLocation() {
            if (this.modal.data.region) {
                return this.translate(`country.${this.modal.data.region.toUpperCase()}`);
            } else {
                return null;
            }
        },

        productCurrency() {
            if (this.modal.data.currency) {
                return this.modal.data.currency.toUpperCase();
            } else {
                return null;
            }
        },
    },

    methods: {
        ...mapActions({
            hideModal: 'ui/hideModal',
        }),
    },
};
</script>

<style lang="scss" scoped>
h1 {
    font-size: 1.3rem;
}

.region {
    background: $color-grey-1;
    padding: 0.8rem;
}
</style>

<i18n src="@dundle/locale/data/buttons.json"></i18n>
<i18n src="@dundle/locale/data/countries.json"></i18n>
<i18n></i18n>
